* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  // min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.layout-theme-light {
  background-color: #edf1f5;
}


.page-title {
  padding-left: 30px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.2px;

  background: linear-gradient(71.27deg, #D60580 1.02%, #00FFFF 97.39%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

h1 {
  margin: 0 !important;
}

.group-upload {
  .upload-input {
    .p-fileupload {
      height: 100%;
      width: 100%;

      .p-button {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.group-type {
  align-items: center;

  .p-dropdown .p-dropdown-label {
    padding: 9px !important;
  }

  * {
    margin-right: 20px;

    &:last-child {
      margin-right: unset;
    }
  }
}

.form-group {
  input, textarea {
    background: transparent;
    font-family: 'Roboto', sans-serif;
    padding: 8px 15px;
    border: 1px solid #E2E8F0;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Greyscale/900 */
    //margin-bottom: 10px;

    color: #1A202C;

  }
}


.basic-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 30px;

  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.2px;
    color: #1A202C;
  }
}


.search {
  margin-bottom: 25px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 15px;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  input {
    border: none;
    outline: none;

    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    color: #718096;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;
      letter-spacing: 0.4px;

      color: #718096;
    }
  }
}


.table-ctn {
  margin-top: 30px;

  tr {
    &.current {
      background: #F6FDF9;
    }
  }

  td {
    border-top: 1px solid #EDF2F7;
    border-bottom: 1px solid #EDF2F7;;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    letter-spacing: 0.2px;

    /* Greyscale/900 */

    color: #1A202C;

    .badge {
      padding: 10px 28px;

      border-radius: 8px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      /* identical to box height, or 21px */

      letter-spacing: 0.2px;

      &.green {
        background: #F6FDF9;
        color: #22C55E;
      }

      &.info {
        background: rgba(9, 117, 224, 0.08);
        color: rgba(9, 117, 224, .7);
      }

      &.red {
        background: rgba(224, 102, 9, 0.08);
        color: rgba(224, 102, 9, .7);
      }

    }
  }

  th {
    padding: 28px 1rem;
    //border-top: 1px solid #EDF2F7;;
    border-bottom: unset !important;
    background: transparent !important;

    span {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      letter-spacing: 0.2px;

      /* Greyscale/600 */

      color: #718096;

    }
  }
}

.action-btn {
  border: 1px solid #EDF2F7;
  border-radius: 8px;
  background: transparent;
  font-family: 'Roboto', sans-serif;
  padding: 10px 22px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.2px;

  /* Greyscale/900 */

  color: #1A202C;

}


.form-group {
  display: flex;
  //flex-direction: column;

  label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 21px */

    letter-spacing: 0.2px;

    /* Greyscale/900 */

    color: #1A202C;
  }
}

.uploads {
  display: flex;
  align-items: center;
  width: 100%;

  .group-upload {
    cursor: pointer;
    margin-right: 20px;

    &:last-child {
      margin-right: unset;
    }

    background: #F1F1F1;
    border: 1px dashed rgba(77, 77, 77, 0.3);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 45px;
    position: relative;

    .upload-image {


      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .delete {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          transition: .3s;
          opacity: 0;
        }
      }

      &:hover {
        button {
          opacity: 1 !important;
          transition: .3s !important;
        }
      }

    }

    .upload-input {
      opacity: 0;
      top: 0px;
      width: 100%;
      height: 100%;
      //top: -30px;
      //width: 120px;
      //height: 70px;
      position: absolute;
    }

    .simple-label {
      margin-top: 10px;
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #0F0F0F;


      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}


.group-text {
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #718096;
  border-radius: 5px;
  padding: 12px 19px;
  display: flex;
  flex-direction: column;
  position: relative;

  &.error {
    border-color: #D60580;
  }

  &:hover {
    border-color: #D60580;

    label {
      color: #D60580;
    }
  }

  label {
    &.required {
      position: relative;
      left: 0;
      top: 0;
    }
  }

  input, textarea {


    &:focus {
      box-shadow: none !important;
    }

    padding: unset;
    width: inherit;
    border: none;


    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* Secondary/Base */

    color: #1A202C;

    &::placeholder {
      font-family: 'Roboto', sans-serif;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      /* identical to box height, or 27px */

      display: flex;
      align-items: center;
      letter-spacing: 0.3px;

      /* Secondary / 300 */

      color: #96A3BE;
    }

  }
}


.stats-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    color: #718096;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.2px;

    &:last-child {
      color: black;
      font-size: 20px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 135%; /* 27px */
      letter-spacing: 0.2px;
    }
  }
}
